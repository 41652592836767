// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/translations.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum avn.connect.v1.TranslationFlags
 */
export enum TranslationFlags {
  /**
   * @generated from enum value: TRANSLATION_FLAGS_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * Is text a base64 AVNFS path? (not yet implemented)
   *
   * @generated from enum value: TRANSLATION_FLAGS_IS_AVNFS = 1;
   */
  IS_AVNFS = 1,

  /**
   * @generated from enum value: TRANSLATION_FLAGS_MEDIA_TYPE_TEXT = 10;
   */
  MEDIA_TYPE_TEXT = 10,

  /**
   * @generated from enum value: TRANSLATION_FLAGS_MEDIA_TYPE_MARKDOWN = 11;
   */
  MEDIA_TYPE_MARKDOWN = 11,

  /**
   * @generated from enum value: TRANSLATION_FLAGS_MEDIA_TYPE_HTML = 12;
   */
  MEDIA_TYPE_HTML = 12,

  /**
   * Translation succeeded
   *
   * @generated from enum value: TRANSLATION_FLAGS_OK = 20;
   */
  OK = 20,

  /**
   * Translation failed
   *
   * @generated from enum value: TRANSLATION_FLAGS_ERROR = 40;
   */
  ERROR = 40,

  /**
   * No translation was needed
   *
   * @generated from enum value: TRANSLATION_FLAGS_SAME_LANGUAGE = 21;
   */
  SAME_LANGUAGE = 21,

  /**
   * Partial translation using dialect rules
   *
   * @generated from enum value: TRANSLATION_FLAGS_DIALECT = 22;
   */
  DIALECT = 22,

  /**
   * The source has no language ID
   *
   * @generated from enum value: TRANSLATION_FLAGS_NEUTRAL_SOURCE = 23;
   */
  NEUTRAL_SOURCE = 23,

  /**
   * Translation could not be found
   *
   * @generated from enum value: TRANSLATION_FLAGS_NOT_FOUND = 41;
   */
  NOT_FOUND = 41,
}
// Retrieve enum metadata with: proto3.getEnumType(TranslationFlags)
proto3.util.setEnumType(TranslationFlags, "avn.connect.v1.TranslationFlags", [
  { no: 0, name: "TRANSLATION_FLAGS_UNKNOWN" },
  { no: 1, name: "TRANSLATION_FLAGS_IS_AVNFS" },
  { no: 10, name: "TRANSLATION_FLAGS_MEDIA_TYPE_TEXT" },
  { no: 11, name: "TRANSLATION_FLAGS_MEDIA_TYPE_MARKDOWN" },
  { no: 12, name: "TRANSLATION_FLAGS_MEDIA_TYPE_HTML" },
  { no: 20, name: "TRANSLATION_FLAGS_OK" },
  { no: 40, name: "TRANSLATION_FLAGS_ERROR" },
  { no: 21, name: "TRANSLATION_FLAGS_SAME_LANGUAGE" },
  { no: 22, name: "TRANSLATION_FLAGS_DIALECT" },
  { no: 23, name: "TRANSLATION_FLAGS_NEUTRAL_SOURCE" },
  { no: 41, name: "TRANSLATION_FLAGS_NOT_FOUND" },
]);

/**
 * @generated from message avn.connect.v1.TranslationRequest
 */
export class TranslationRequest extends Message<TranslationRequest> {
  /**
   * Source text (immediate text string or AVNFS reference)
   *
   * @generated from field: string source = 1;
   */
  source = "";

  /**
   * Language of the source text
   *
   * @generated from field: string source_language_id = 2;
   */
  sourceLanguageId = "";

  /**
   * Language to translate to
   *
   * @generated from field: string target_language_id = 3;
   */
  targetLanguageId = "";

  /**
   * Differential modifier for short or ambiguous text (currently implemented as a prefix for backward compatibility with CC1)
   *
   * @generated from field: string modifier = 4;
   */
  modifier = "";

  /**
   * Additional context
   *
   * @generated from field: repeated avn.connect.v1.TranslationFlags flags = 5;
   */
  flags: TranslationFlags[] = [];

  constructor(data?: PartialMessage<TranslationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.TranslationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "source_language_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "target_language_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "modifier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "flags", kind: "enum", T: proto3.getEnumType(TranslationFlags), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TranslationRequest {
    return new TranslationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TranslationRequest {
    return new TranslationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TranslationRequest {
    return new TranslationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TranslationRequest | PlainMessage<TranslationRequest> | undefined, b: TranslationRequest | PlainMessage<TranslationRequest> | undefined): boolean {
    return proto3.util.equals(TranslationRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.Translation
 */
export class Translation extends Message<Translation> {
  /**
   * Translated text (immediate text string or AVNFS reference)
   *
   * @generated from field: string translation = 1;
   */
  translation = "";

  /**
   * Language of the source text
   *
   * @generated from field: string source_language_id = 2;
   */
  sourceLanguageId = "";

  /**
   * Language to translate to
   *
   * @generated from field: string target_language_id = 3;
   */
  targetLanguageId = "";

  /**
   * Unique ID for identifying translation, based on source text and modifier 
   *
   * @generated from field: string translation_id = 4;
   */
  translationId = "";

  /**
   * Additional context
   *
   * @generated from field: repeated avn.connect.v1.TranslationFlags flags = 5;
   */
  flags: TranslationFlags[] = [];

  constructor(data?: PartialMessage<Translation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Translation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "translation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "source_language_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "target_language_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "translation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "flags", kind: "enum", T: proto3.getEnumType(TranslationFlags), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Translation {
    return new Translation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Translation {
    return new Translation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Translation {
    return new Translation().fromJsonString(jsonString, options);
  }

  static equals(a: Translation | PlainMessage<Translation> | undefined, b: Translation | PlainMessage<Translation> | undefined): boolean {
    return proto3.util.equals(Translation, a, b);
  }
}


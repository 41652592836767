// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/organizations.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Authorization } from "./authorization_pb.js";
import { Organization, OrganizationSpec } from "./organization_pb.js";

/**
 * @generated from message avn.connect.v1.JoinOrganizationRequest
 */
export class JoinOrganizationRequest extends Message<JoinOrganizationRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: string join_code = 2;
   */
  joinCode = "";

  constructor(data?: PartialMessage<JoinOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.JoinOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "join_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JoinOrganizationRequest {
    return new JoinOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JoinOrganizationRequest {
    return new JoinOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JoinOrganizationRequest {
    return new JoinOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: JoinOrganizationRequest | PlainMessage<JoinOrganizationRequest> | undefined, b: JoinOrganizationRequest | PlainMessage<JoinOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(JoinOrganizationRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.JoinOrganizationResponse
 */
export class JoinOrganizationResponse extends Message<JoinOrganizationResponse> {
  constructor(data?: PartialMessage<JoinOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.JoinOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JoinOrganizationResponse {
    return new JoinOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JoinOrganizationResponse {
    return new JoinOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JoinOrganizationResponse {
    return new JoinOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: JoinOrganizationResponse | PlainMessage<JoinOrganizationResponse> | undefined, b: JoinOrganizationResponse | PlainMessage<JoinOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(JoinOrganizationResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.CreateNewUserOrganizationRequest
 */
export class CreateNewUserOrganizationRequest extends Message<CreateNewUserOrganizationRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: avn.connect.v1.OrganizationSpec organization_spec = 2;
   */
  organizationSpec?: OrganizationSpec;

  constructor(data?: PartialMessage<CreateNewUserOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CreateNewUserOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "organization_spec", kind: "message", T: OrganizationSpec },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateNewUserOrganizationRequest {
    return new CreateNewUserOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateNewUserOrganizationRequest {
    return new CreateNewUserOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateNewUserOrganizationRequest {
    return new CreateNewUserOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateNewUserOrganizationRequest | PlainMessage<CreateNewUserOrganizationRequest> | undefined, b: CreateNewUserOrganizationRequest | PlainMessage<CreateNewUserOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(CreateNewUserOrganizationRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.CreateNewUserOrganizationResponse
 */
export class CreateNewUserOrganizationResponse extends Message<CreateNewUserOrganizationResponse> {
  /**
   * @generated from field: avn.connect.v1.Organization organization = 1;
   */
  organization?: Organization;

  constructor(data?: PartialMessage<CreateNewUserOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CreateNewUserOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization", kind: "message", T: Organization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateNewUserOrganizationResponse {
    return new CreateNewUserOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateNewUserOrganizationResponse {
    return new CreateNewUserOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateNewUserOrganizationResponse {
    return new CreateNewUserOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateNewUserOrganizationResponse | PlainMessage<CreateNewUserOrganizationResponse> | undefined, b: CreateNewUserOrganizationResponse | PlainMessage<CreateNewUserOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(CreateNewUserOrganizationResponse, a, b);
  }
}


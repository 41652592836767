// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/rooms.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { EnterRoomRequest, EnterRoomResponse, GetRoomDimensionRequest, GetRoomDimensionResponse, GetRoomRequest, GetRoomResponse, OpenRoomRequest, OpenRoomResponse, ResolveMediaRequest, ResolveMediaResponse } from "./rooms_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.RoomService
 */
export const RoomService = {
  typeName: "avn.connect.v1.RoomService",
  methods: {
    /**
     * Lookup a room by ID
     *
     * @generated from rpc avn.connect.v1.RoomService.GetRoom
     */
    getRoom: {
      name: "GetRoom",
      I: GetRoomRequest,
      O: GetRoomResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Open a room for a given dimension-URL combination (may return an existing room)
     *
     * @generated from rpc avn.connect.v1.RoomService.OpenRoom
     */
    openRoom: {
      name: "OpenRoom",
      I: OpenRoomRequest,
      O: OpenRoomResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.RoomService.GetRoomDimension
     */
    getRoomDimension: {
      name: "GetRoomDimension",
      I: GetRoomDimensionRequest,
      O: GetRoomDimensionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Record entry to a room
     *
     * @generated from rpc avn.connect.v1.RoomService.EnterRoom
     */
    enterRoom: {
      name: "EnterRoom",
      I: EnterRoomRequest,
      O: EnterRoomResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Legacy function for hubs media resolution
     *
     * @generated from rpc avn.connect.v1.RoomService.ResolveMedia
     */
    resolveMedia: {
      name: "ResolveMedia",
      I: ResolveMediaRequest,
      O: ResolveMediaResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;


import { ConnectServices } from "./ConnectServices.js"
import * as MediaUtils from "./media-utils-client.js"
import * as CryptoUtils from "./crypto-utils-client.js"
import * as Environment from "./environment-utils-client.js"

import { Authorization } from "../../gen/avn/connect/v1/authorization_pb.js"
import { GetFileUrlRequest } from "../../gen/avn/connect/v1/avnfs_pb.js"

export const Hostname = "avnfs.com"
export const UrlPrefix = `https://${Hostname}`

export const DuplicateUploadEventType = "duplicate"

export const TypeQueryParam = "type"
export const SizeQueryParam = "size"
export const NameQueryParam = "name"

export type FileSpec = { hash: string, sizeBytes: number, mediaType: string, fileName?: string }


// TODO: more detailed checking (AVN_CORE_CANDIDATE)
export function isValidUrl(url: URL) : boolean {
    if(url.hostname != Hostname) {
        return false
    }
    if(!url.pathname) {
        return false
    }
    if(!url.searchParams.get(SizeQueryParam)) {
        return false
    }
    if(!url.searchParams.get(TypeQueryParam)) {
        return false
    }
    return true
}

export function decodeUrl(url: URL) : FileSpec {
	return {
		hash: url.pathname.substring(1),
		sizeBytes: parseInt(url.searchParams.get(SizeQueryParam)!),
		mediaType: url.searchParams.get(TypeQueryParam)!,
		fileName: url.searchParams.get(NameQueryParam) || undefined,
	}
}

export function encodeUrl(spec: FileSpec) : URL {
	const url = new URL(UrlPrefix + "/" + spec.hash)
	url.searchParams.append(SizeQueryParam, spec.sizeBytes.toString())
	url.searchParams.append(TypeQueryParam, spec.mediaType)
	if(spec.fileName) {
		url.searchParams.append(NameQueryParam, spec.fileName)
	}
	return url
}

export function updateFilename(url: URL, name: string | undefined) : URL {
    const newUrl = new URL(url)
    newUrl.searchParams.delete(NameQueryParam)
    if(name) {
        newUrl.searchParams.append(NameQueryParam, name)
    }
	return newUrl
}

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
  
// S3 POST operations can have a small delay before availability so this function waits until it is confirmed
async function waitUntilFileIsAvailable(ConnectServices: ConnectServices, fileSignature: GetFileUrlRequest) {
    // Don't wait longer than 100 * 10ms = 1s because that implies the upload has failed
    let maxWaitCount = 100
    while(maxWaitCount > 0) {
        const result = await ConnectServices.Avnfs.getFileUrl(fileSignature)
        if(result.url) {
            return
        }
        console.log(`Waiting for upload confirmation for '${fileSignature.hash}'...`)
        await sleep(10)
        --maxWaitCount
    }
    console.warn(`Upload confirmation failed for '${fileSignature.hash}'`)
}

export async function uploadArrayBuffer(
	buffer: ArrayBuffer, 
    ConnectServices: ConnectServices,
	auth: Authorization,
    mediaType: string,
    fileName: string | undefined = undefined,
	progressCallback: ((event: ProgressEvent) => any) | undefined = undefined,
	signal: AbortSignal | undefined = undefined,
) : Promise<URL> {
    const sizeBytes = BigInt(buffer.byteLength)
    // AVN_CORE_CANDIDATE could be constant
    if(sizeBytes > 5368709120) {
        throw new Error(`File size is ${sizeBytes} bytes, which exceeds the storage limit of 5368709120`)
    }
	const hash = await CryptoUtils.base64UrlHashForArrayBuffer(buffer)
    const fileSignature = new GetFileUrlRequest({ hash, sizeBytes, mediaType, fileName })
    const getFileResponse = await ConnectServices.Avnfs.getFileUrl(fileSignature)
    // Does file already exist?
    if(getFileResponse.url) {
        console.log(`File '${fileName || hash}' already exists`)
        progressCallback?.(new ProgressEvent(DuplicateUploadEventType, { loaded: buffer.byteLength, total: buffer.byteLength }))
        return new URL(getFileResponse.url)
	}
    console.log(`Uploading file '${fileName || hash}'...`)    
    const uploadManifest = await ConnectServices.Avnfs.getPostManifest({ auth, ...fileSignature })
    const formData = new FormData()
    for(let field of uploadManifest.headerFields) {
        formData.append(field.name, field.value)
    }
    formData.append("file", new Blob([buffer]))
    const downloadUrl = new URL(uploadManifest.downloadUrl)
    // XMLHttpRequest is only available in a browser context
    if(typeof XMLHttpRequest != "undefined") {
        // Promisify XMLHttpRequest (fetch doesn't easily support progress)
        return new Promise(function(resolve, reject) {
            const ajax = new XMLHttpRequest()
            ajax.open("POST", uploadManifest.uploadUrl)
            ajax.upload.onprogress = (event: ProgressEvent) => {
                progressCallback?.(event)
                if(signal?.aborted) {
                    console.info(`Upload aborted: ${signal?.reason}`)
                    ajax.abort()
                } 
            }
            ajax.onload = async function() {
                if (ajax.status >= 200 && ajax.status < 300) {
                    console.log(`Send complete with status ${ajax.status} for '${hash}'`)
                    await waitUntilFileIsAvailable(ConnectServices, fileSignature)
                    resolve(downloadUrl)
                } else {
                    reject(Error(`Upload failure (${ajax.statusText || "unknown cause"})`))
                }
            }
            ajax.onerror = function() {
                reject(Error(`Network failure (${ajax.statusText || "unknown cause"})`))
            }
            ajax.onabort = (event) => {
                reject(Error(`Upload was aborted`))
            }
            console.log(`Sending file to '${hash}'...`)
            ajax.send(formData)
        })     
    } else {
		const res = new Response(formData)
		console.log(`Uploading '${hash}'...`)
		const uploadResponse = await fetch(uploadManifest.uploadUrl, {
			method: "POST",
			body: await res.blob(),
		})
		if(uploadResponse.ok) {
			return downloadUrl
		} else {
			console.error(`Upload failure for '${hash}': ${uploadResponse.statusText} (${uploadResponse.status})`, await uploadResponse.text())
			throw new Error(`Upload failure`)
		}
    }
}

export async function uploadFilePath(
	path: string, 
    Connect: ConnectServices,
	auth: Authorization,
    mediaType: string,
    fileName: string | undefined = undefined,
    // Progress callback should return `true` to abort during upload
	progressCallback: ((event: ProgressEvent) => any) | undefined = undefined,
	signal: AbortSignal | undefined = undefined,
) : Promise<URL> {
    //TODO: create streaming version so the whole file doesn't need to be in memory
    const fs = Environment.isBrowser ? undefined : await import("fs")
    if(!fs) {
        throw new Error("fs not available")
    }
    const buffer = fs.readFileSync(path)
    return await uploadArrayBuffer(buffer, Connect, auth, mediaType, fileName, progressCallback, signal)
}

export async function uploadFile(
	file: File, 
    hash: string | undefined,
    ConnectServices: ConnectServices,
	auth: Authorization,
    // Optional override
    mediaTypeOverride: string | undefined = undefined,
    // Optional override
    fileNameOverride: string | undefined = undefined,
	progressCallback: ((event: ProgressEvent) => any) | undefined = undefined,
	signal: AbortSignal | undefined = undefined,
) : Promise<URL> {
    // Calculate hash if not already provided
	hash ??= await CryptoUtils.base64UrlHashForFile(file)
    const sizeBytes = BigInt(file.size)
    mediaTypeOverride = mediaTypeOverride || await MediaUtils.guessMimeTypeForFile(ConnectServices, file) || MediaUtils.GenericMediaType
    fileNameOverride = fileNameOverride || file.name
    // Does file already exist?
    const fileSignature = new GetFileUrlRequest({ hash, sizeBytes, mediaType: mediaTypeOverride, fileName: fileNameOverride })
    const getFileResponse = await ConnectServices.Avnfs.getFileUrl(fileSignature)
    if(getFileResponse.url) {
        console.log(`File '${fileNameOverride}' already exists`)
        progressCallback?.(new ProgressEvent(DuplicateUploadEventType, { loaded: file.size, total: file.size }))
        return new URL(getFileResponse.url)
	}
    console.log(`Uploading file '${fileNameOverride}'...`)    
    const postManifest = await ConnectServices.Avnfs.getPostManifest({ auth, ...fileSignature })
    const formData = new FormData()
    for(let field of postManifest.headerFields) {
        formData.append(field.name, field.value)
    }
    formData.append("file", file)
    // Promisify XMLHttpRequest (fetch doesn't easily support progress)
    return new Promise(function(resolve, reject) {
        const ajax = new XMLHttpRequest()
        ajax.open("POST", postManifest.uploadUrl)
        if(progressCallback) {
            ajax.upload.onprogress = (event: ProgressEvent) => {
                progressCallback?.(event)
                if(signal?.aborted) {
                    console.info(`Upload aborted: ${signal?.reason}`)
                    ajax.abort()
                }
            } 
        }
        ajax.onload = async (event) => {
            if (ajax.status >= 200 && ajax.status < 300) {
                console.log(`Send complete with status ${ajax.status} for '${hash}'`)
                await waitUntilFileIsAvailable(ConnectServices, fileSignature)
                resolve(new URL(postManifest.downloadUrl))
            } else {
                reject(Error(`Upload failure (${ajax.statusText || "unknown cause"})`))
            }
        }
        ajax.onerror = (event) => {
            reject(Error(`Network failure (${ajax.statusText || "unknown cause"})`))
        }
        ajax.onabort = (event) => {
            reject(Error(`Upload was aborted`))
        }
        console.log(`Sending file to '${hash}'...`)
        ajax.send(formData)
    })     
    
}

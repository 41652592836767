// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/authorization.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { ConnectionCredentials } from "./connections_pb.js";

/**
 * @generated from enum avn.connect.v1.IdentityProvider
 */
export enum IdentityProvider {
  /**
   * @generated from enum value: IDENTITY_PROVIDER_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: IDENTITY_PROVIDER_ANONYMOUS = 1;
   */
  ANONYMOUS = 1,

  /**
   * @generated from enum value: IDENTITY_PROVIDER_CLASSCONNECT = 2;
   */
  CLASSCONNECT = 2,

  /**
   * @generated from enum value: IDENTITY_PROVIDER_GOOGLE = 3;
   */
  GOOGLE = 3,

  /**
   * @generated from enum value: IDENTITY_PROVIDER_MICROSOFT = 4;
   */
  MICROSOFT = 4,

  /**
   * @generated from enum value: IDENTITY_PROVIDER_CLEVER = 5;
   */
  CLEVER = 5,

  /**
   * @generated from enum value: IDENTITY_PROVIDER_CLASSLINK = 6;
   */
  CLASSLINK = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(IdentityProvider)
proto3.util.setEnumType(IdentityProvider, "avn.connect.v1.IdentityProvider", [
  { no: 0, name: "IDENTITY_PROVIDER_UNSPECIFIED" },
  { no: 1, name: "IDENTITY_PROVIDER_ANONYMOUS" },
  { no: 2, name: "IDENTITY_PROVIDER_CLASSCONNECT" },
  { no: 3, name: "IDENTITY_PROVIDER_GOOGLE" },
  { no: 4, name: "IDENTITY_PROVIDER_MICROSOFT" },
  { no: 5, name: "IDENTITY_PROVIDER_CLEVER" },
  { no: 6, name: "IDENTITY_PROVIDER_CLASSLINK" },
]);

/**
 * @generated from message avn.connect.v1.Authorization
 */
export class Authorization extends Message<Authorization> {
  /**
   * @generated from oneof avn.connect.v1.Authorization.method
   */
  method: {
    /**
     * The OpenID JWT of the authenticated user
     *
     * @generated from field: string user_jwt = 1;
     */
    value: string;
    case: "userJwt";
  } | {
    /**
     * Just the dimension ID
     *
     * @generated from field: string dimension_id = 2;
     */
    value: string;
    case: "dimensionId";
  } | {
    /**
     * The confirmed connection credentials
     *
     * @generated from field: avn.connect.v1.ConnectionCredentials credentials = 3;
     */
    value: ConnectionCredentials;
    case: "credentials";
  } | {
    /**
     * Fixed API key
     *
     * @generated from field: string user_api_key = 4;
     */
    value: string;
    case: "userApiKey";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Authorization>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Authorization";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_jwt", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "method" },
    { no: 2, name: "dimension_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "method" },
    { no: 3, name: "credentials", kind: "message", T: ConnectionCredentials, oneof: "method" },
    { no: 4, name: "user_api_key", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "method" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Authorization {
    return new Authorization().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Authorization {
    return new Authorization().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Authorization {
    return new Authorization().fromJsonString(jsonString, options);
  }

  static equals(a: Authorization | PlainMessage<Authorization> | undefined, b: Authorization | PlainMessage<Authorization> | undefined): boolean {
    return proto3.util.equals(Authorization, a, b);
  }
}


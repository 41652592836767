// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/channels.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { EntityInfoListRequest, EntityInfoListResponse, GetEntityRequest } from "./entities_pb.js";
import { Channel } from "./channels_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.ChannelService
 */
export const ChannelService = {
  typeName: "avn.connect.v1.ChannelService",
  methods: {
    /**
     * Get a specific channel
     *
     * @generated from rpc avn.connect.v1.ChannelService.GetChannel
     */
    getChannel: {
      name: "GetChannel",
      I: GetEntityRequest,
      O: Channel,
      kind: MethodKind.Unary,
    },
    /**
     * Get all channels available with the given authorization credentials
     *
     * @generated from rpc avn.connect.v1.ChannelService.GetBrowsableChannels
     */
    getBrowsableChannels: {
      name: "GetBrowsableChannels",
      I: EntityInfoListRequest,
      O: EntityInfoListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get all published profiles in the given set of channels
     *
     * @generated from rpc avn.connect.v1.ChannelService.GetProfiles
     */
    getProfiles: {
      name: "GetProfiles",
      I: EntityInfoListRequest,
      O: EntityInfoListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get all published categories in the given set of channels
     *
     * @generated from rpc avn.connect.v1.ChannelService.GetCategories
     */
    getCategories: {
      name: "GetCategories",
      I: EntityInfoListRequest,
      O: EntityInfoListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get all published activities in the given set of channels
     *
     * @generated from rpc avn.connect.v1.ChannelService.GetActivities
     */
    getActivities: {
      name: "GetActivities",
      I: EntityInfoListRequest,
      O: EntityInfoListResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;


export const Flat = 2715
export const Equirectangular360 = 2363
export const Equirectangular180 = 2550
export const Cubemap = 2373
export const StereoTopBottomLR = 2362
export const StereoSideBySide = 2364
export const AR = 2367
export const VR = 2366
export const SceneGuide = 2656
export const LessonPlan = 2372
export const Worksheet = 2371
export const Animated = 2613
export const Scene = 2375
export const Avatar = 2666
export const NotBrowsable = 2427
export const Navigable = 2683
export const Model = 2374
export const Audio = 2655
export const Image = 2376
export const Video = 2377

// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/profiles.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { EntityInfoListRequest, EntityInfoListResponse, GetEntityRequest, SetEntityPropertiesRequest } from "./entities_pb.js";
import { Profile } from "./profiles_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.ProfileService
 */
export const ProfileService = {
  typeName: "avn.connect.v1.ProfileService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.ProfileService.GetProfile
     */
    getProfile: {
      name: "GetProfile",
      I: GetEntityRequest,
      O: Profile,
      kind: MethodKind.Unary,
    },
    /**
     * Get all published categories in the given profile
     *
     * @generated from rpc avn.connect.v1.ProfileService.GetCategories
     */
    getCategories: {
      name: "GetCategories",
      I: EntityInfoListRequest,
      O: EntityInfoListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get all published activities in the given profile
     *
     * @generated from rpc avn.connect.v1.ProfileService.GetActivities
     */
    getActivities: {
      name: "GetActivities",
      I: EntityInfoListRequest,
      O: EntityInfoListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.ProfileService.SetProperties
     */
    setProperties: {
      name: "SetProperties",
      I: SetEntityPropertiesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;


// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/content.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message avn.connect.v1.AvailableContent
 */
export class AvailableContent extends Message<AvailableContent> {
  /**
   * Hint of what channels should be shown for browsing
   *
   * @generated from field: repeated int32 browsable_channels = 1;
   */
  browsableChannels: number[] = [];

  /**
   * Published content from these channels is available 
   *
   * @generated from field: repeated int32 licensed_channels = 2;
   */
  licensedChannels: number[] = [];

  /**
   * Published content in these categories is available
   *
   * @generated from field: repeated int32 licensed_categories = 4;
   */
  licensedCategories: number[] = [];

  /**
   * Published content owned exclusively by these users is available (i.e. from the users playlists)
   *
   * @generated from field: repeated int32 licensed_users = 5;
   */
  licensedUsers: number[] = [];

  /**
   * DEPRECATED IN FAVOUR OF TagFilter SYSTEM LIKE IN entities.proto
   * Future extension for filtering appropriate content for dimensions
   *
   * @generated from field: repeated int32 tag_whitelist = 10;
   */
  tagWhitelist: number[] = [];

  /**
   * @generated from field: repeated int32 tag_blacklist = 11;
   */
  tagBlacklist: number[] = [];

  constructor(data?: PartialMessage<AvailableContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.AvailableContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "browsable_channels", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 2, name: "licensed_channels", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 4, name: "licensed_categories", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 5, name: "licensed_users", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 10, name: "tag_whitelist", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 11, name: "tag_blacklist", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AvailableContent {
    return new AvailableContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AvailableContent {
    return new AvailableContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AvailableContent {
    return new AvailableContent().fromJsonString(jsonString, options);
  }

  static equals(a: AvailableContent | PlainMessage<AvailableContent> | undefined, b: AvailableContent | PlainMessage<AvailableContent> | undefined): boolean {
    return proto3.util.equals(AvailableContent, a, b);
  }
}


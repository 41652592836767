// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/rooms.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { ConnectionCredentials } from "./connections_pb.js";

/**
 * @generated from message avn.connect.v1.RoomInfo
 */
export class RoomInfo extends Message<RoomInfo> {
  /**
   * @generated from field: string domain = 1;
   */
  domain = "";

  /**
   * @generated from field: string dimension_id = 2;
   */
  dimensionId = "";

  /**
   * @generated from field: string room_id = 3;
   */
  roomId = "";

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * @generated from field: string icon_url = 5;
   */
  iconUrl = "";

  /**
   * @generated from field: string screenshot_url = 6;
   */
  screenshotUrl = "";

  /**
   * @generated from field: string asset_id = 7;
   */
  assetId = "";

  /**
   * @generated from field: int32 activity_id = 8;
   */
  activityId = 0;

  /**
   * @generated from field: google.protobuf.Timestamp created = 9;
   */
  created?: Timestamp;

  /**
   * Additional parameters that define the room
   *
   * @generated from field: map<string, string> asset_parameters = 10;
   */
  assetParameters: { [key: string]: string } = {};

  constructor(data?: PartialMessage<RoomInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.RoomInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dimension_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "screenshot_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "asset_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "activity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "created", kind: "message", T: Timestamp },
    { no: 10, name: "asset_parameters", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RoomInfo {
    return new RoomInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RoomInfo {
    return new RoomInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RoomInfo {
    return new RoomInfo().fromJsonString(jsonString, options);
  }

  static equals(a: RoomInfo | PlainMessage<RoomInfo> | undefined, b: RoomInfo | PlainMessage<RoomInfo> | undefined): boolean {
    return proto3.util.equals(RoomInfo, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetRoomRequest
 */
export class GetRoomRequest extends Message<GetRoomRequest> {
  /**
   * @generated from field: string room_id = 1;
   */
  roomId = "";

  constructor(data?: PartialMessage<GetRoomRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetRoomRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRoomRequest {
    return new GetRoomRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRoomRequest {
    return new GetRoomRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRoomRequest {
    return new GetRoomRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRoomRequest | PlainMessage<GetRoomRequest> | undefined, b: GetRoomRequest | PlainMessage<GetRoomRequest> | undefined): boolean {
    return proto3.util.equals(GetRoomRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetRoomResponse
 */
export class GetRoomResponse extends Message<GetRoomResponse> {
  /**
   * @generated from field: avn.connect.v1.RoomInfo room_info = 1;
   */
  roomInfo?: RoomInfo;

  constructor(data?: PartialMessage<GetRoomResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetRoomResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "room_info", kind: "message", T: RoomInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRoomResponse {
    return new GetRoomResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRoomResponse {
    return new GetRoomResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRoomResponse {
    return new GetRoomResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRoomResponse | PlainMessage<GetRoomResponse> | undefined, b: GetRoomResponse | PlainMessage<GetRoomResponse> | undefined): boolean {
    return proto3.util.equals(GetRoomResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.OpenRoomRequest
 */
export class OpenRoomRequest extends Message<OpenRoomRequest> {
  /**
   * @generated from field: string dimension_id = 1;
   */
  dimensionId = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  constructor(data?: PartialMessage<OpenRoomRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.OpenRoomRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dimension_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OpenRoomRequest {
    return new OpenRoomRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OpenRoomRequest {
    return new OpenRoomRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OpenRoomRequest {
    return new OpenRoomRequest().fromJsonString(jsonString, options);
  }

  static equals(a: OpenRoomRequest | PlainMessage<OpenRoomRequest> | undefined, b: OpenRoomRequest | PlainMessage<OpenRoomRequest> | undefined): boolean {
    return proto3.util.equals(OpenRoomRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.OpenRoomResponse
 */
export class OpenRoomResponse extends Message<OpenRoomResponse> {
  /**
   * @generated from field: avn.connect.v1.RoomInfo room_info = 1;
   */
  roomInfo?: RoomInfo;

  constructor(data?: PartialMessage<OpenRoomResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.OpenRoomResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "room_info", kind: "message", T: RoomInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OpenRoomResponse {
    return new OpenRoomResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OpenRoomResponse {
    return new OpenRoomResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OpenRoomResponse {
    return new OpenRoomResponse().fromJsonString(jsonString, options);
  }

  static equals(a: OpenRoomResponse | PlainMessage<OpenRoomResponse> | undefined, b: OpenRoomResponse | PlainMessage<OpenRoomResponse> | undefined): boolean {
    return proto3.util.equals(OpenRoomResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.EnterRoomRequest
 */
export class EnterRoomRequest extends Message<EnterRoomRequest> {
  /**
   * @generated from field: avn.connect.v1.ConnectionCredentials credentials = 1;
   */
  credentials?: ConnectionCredentials;

  /**
   * @generated from field: string room_id = 2;
   */
  roomId = "";

  /**
   * @generated from field: string session_id = 3;
   */
  sessionId = "";

  constructor(data?: PartialMessage<EnterRoomRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.EnterRoomRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "credentials", kind: "message", T: ConnectionCredentials },
    { no: 2, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnterRoomRequest {
    return new EnterRoomRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnterRoomRequest {
    return new EnterRoomRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnterRoomRequest {
    return new EnterRoomRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EnterRoomRequest | PlainMessage<EnterRoomRequest> | undefined, b: EnterRoomRequest | PlainMessage<EnterRoomRequest> | undefined): boolean {
    return proto3.util.equals(EnterRoomRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.EnterRoomResponse
 */
export class EnterRoomResponse extends Message<EnterRoomResponse> {
  /**
   * @generated from field: avn.connect.v1.RoomInfo room_info = 1;
   */
  roomInfo?: RoomInfo;

  constructor(data?: PartialMessage<EnterRoomResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.EnterRoomResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "room_info", kind: "message", T: RoomInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnterRoomResponse {
    return new EnterRoomResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnterRoomResponse {
    return new EnterRoomResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnterRoomResponse {
    return new EnterRoomResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EnterRoomResponse | PlainMessage<EnterRoomResponse> | undefined, b: EnterRoomResponse | PlainMessage<EnterRoomResponse> | undefined): boolean {
    return proto3.util.equals(EnterRoomResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetRoomDimensionRequest
 */
export class GetRoomDimensionRequest extends Message<GetRoomDimensionRequest> {
  /**
   * @generated from field: string room_id = 1;
   */
  roomId = "";

  constructor(data?: PartialMessage<GetRoomDimensionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetRoomDimensionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRoomDimensionRequest {
    return new GetRoomDimensionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRoomDimensionRequest {
    return new GetRoomDimensionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRoomDimensionRequest {
    return new GetRoomDimensionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRoomDimensionRequest | PlainMessage<GetRoomDimensionRequest> | undefined, b: GetRoomDimensionRequest | PlainMessage<GetRoomDimensionRequest> | undefined): boolean {
    return proto3.util.equals(GetRoomDimensionRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetRoomDimensionResponse
 */
export class GetRoomDimensionResponse extends Message<GetRoomDimensionResponse> {
  /**
   * @generated from field: string dimension_id = 1;
   */
  dimensionId = "";

  constructor(data?: PartialMessage<GetRoomDimensionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetRoomDimensionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dimension_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRoomDimensionResponse {
    return new GetRoomDimensionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRoomDimensionResponse {
    return new GetRoomDimensionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRoomDimensionResponse {
    return new GetRoomDimensionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRoomDimensionResponse | PlainMessage<GetRoomDimensionResponse> | undefined, b: GetRoomDimensionResponse | PlainMessage<GetRoomDimensionResponse> | undefined): boolean {
    return proto3.util.equals(GetRoomDimensionResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.ResolveMediaRequest
 */
export class ResolveMediaRequest extends Message<ResolveMediaRequest> {
  /**
   * @generated from field: string dimension_id = 1;
   */
  dimensionId = "";

  /**
   * @generated from field: string asset_id = 2;
   */
  assetId = "";

  constructor(data?: PartialMessage<ResolveMediaRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.ResolveMediaRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dimension_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "asset_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResolveMediaRequest {
    return new ResolveMediaRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResolveMediaRequest {
    return new ResolveMediaRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResolveMediaRequest {
    return new ResolveMediaRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResolveMediaRequest | PlainMessage<ResolveMediaRequest> | undefined, b: ResolveMediaRequest | PlainMessage<ResolveMediaRequest> | undefined): boolean {
    return proto3.util.equals(ResolveMediaRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.ResolveMediaResponse
 */
export class ResolveMediaResponse extends Message<ResolveMediaResponse> {
  /**
   * @generated from field: string asset_url = 1;
   */
  assetUrl = "";

  /**
   * @generated from field: string thumbnail_url = 2;
   */
  thumbnailUrl = "";

  /**
   * @generated from field: string mime_type = 3;
   */
  mimeType = "";

  /**
   * @generated from field: repeated int32 tag_ids = 4;
   */
  tagIds: number[] = [];

  constructor(data?: PartialMessage<ResolveMediaResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.ResolveMediaResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "asset_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "thumbnail_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "tag_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResolveMediaResponse {
    return new ResolveMediaResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResolveMediaResponse {
    return new ResolveMediaResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResolveMediaResponse {
    return new ResolveMediaResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResolveMediaResponse | PlainMessage<ResolveMediaResponse> | undefined, b: ResolveMediaResponse | PlainMessage<ResolveMediaResponse> | undefined): boolean {
    return proto3.util.equals(ResolveMediaResponse, a, b);
  }
}

